@import "buttons.scss";
@import "fontawesome/fontawesome.scss";
@import "fontawesome/solid.scss";

.form-control {
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex: 1;

	&.checkbox {
		flex-direction: row;
		gap: 15px;
		align-items: center;
		label {
			cursor: pointer;
			@extend .text-button;
		}
	}

	.form-control-input {
		position: relative;
		min-height: 38px;
		background: #ffffff;
		border: 1px solid $input-line;
		border-radius: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 6px 24px;
		input,
		select,
		textarea {
			width: 100%;
			height: 90%;
			border: 0;
			@extend .input-inside;

			&:focus-visible {
				border: 0;
				outline: none;
			}
		}

		select {
			cursor: pointer;
			option[value=""] {
				color: $text-light-gray;
			}
		}

		.input-icons {
			display: flex;
			gap: 10px;
			.exc {
				display: none;
				color: $error;
			}
		}

		.password-switch {
			cursor: pointer;
		}
	}

	.form-control-select {
		@extend .form-control-input;
	}

	.form-control-badges {
		@extend .form-control-input;
		height: fit-content;
		justify-content: initial;
		input {
			width: max-content;
			height: initial;
		}
	}

	.form-control-checkbox {
		input {
			width: 24px;
			height: 25px;
			border-radius: 6px;
			border: 1px solid $input-line;
			appearance: none;
			&:checked {
				background-color: $primary-color;
				border: none;
			}
		}
	}

	label {
		@extend .input-label;
		&.required {
			&::after {
				content: "*povinné";
				float: right;
				color: $text-light-gray;
			}
		}
	}

	.error-message {
		@extend .input-label;
		color: $error;
	}
	.has-error {
		border-color: $error;
		.exc {
			display: initial !important;
		}
	}

	.preview-image {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		position: relative;
		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
		}
		.preview-image__delete {
			position: absolute;
			font-size: 12px;
			padding: 2px 12px;
			top: 10px;
			right: 15px;
			cursor: pointer;
		}
	}
}
.submit-button {
	align-self: center;
}

form {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: inherit;
}
