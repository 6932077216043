@import "manage.scss";

.btn {
	width: fit-content;
	height: fit-content;
	background-color: $primary-color;
	@extend .text-button;
	color: black;
	border: 0;
	border-radius: 10px;
	padding: 8px 25px;

	i {
		margin-right: 10px;
	}

	&.btn-primary {
		background-color: $primary-color;
		&:hover,
		&:active {
			background-color: darken($primary-color, 5%) !important;
		}
		color: white;
	}

	&.btn-secondary {
		background-color: $btn-gray;
		color: $text-dark-gray;
		&:hover,
		&:active {
			background-color: darken($btn-gray, 5%) !important;
		}
	}

	&.btn-danger {
		background-color: $error;
		&:hover,
		&:active {
			background-color: darken($error, 5%) !important;
		}
		color: white;
	}

	&.btn-delete {
		background-color: $btn-delete;
		&:hover,
		&:active {
			background-color: darken($btn-delete, 5%) !important;
		}
		color: white;
	}

	&.btn-void {
		background-color: initial;
	}

	&.btn-min {
		padding: 0;
	}

	&.btn-sm {
		padding: 4px 12px;
	}

	&.btn-big {
		padding: 13px 35px;
	}
}
