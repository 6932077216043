@import "forms.scss";

$bg-success: #32a852;
$bg-warning: #b1b134;
$bg-info: #4eb3de;
$bg-danger: #de3d28;
$opacity: 0.8;

.flash-message-container {
	padding-top: 68px;
	display: flex;
	flex-direction: column;
	min-height: 30px;
	@extend .text;

	.flash-message-box {
		display: none;
		color: white;
		&.open {
			display: initial;
		}
		&.success {
			background-color: rgba($bg-success, $opacity);
			.icon::before {
				content: fa-content($fa-var-circle-check);
			}
		}

		&.danger {
			background-color: rgba($bg-danger, $opacity);
			.icon::before {
				content: fa-content($fa-var-circle-exclamation);
			}
		}

		&.info {
			background-color: rgba($bg-info, $opacity);
			.icon::before {
				content: fa-content($fa-var-circle-info);
			}
		}

		&.warning {
			background-color: rgba($bg-warning, $opacity);
			.icon::before {
				content: fa-content($fa-var-triangle-exclamation);
			}
		}
	}
	.flash-message {
		padding: 10px 20px;
		display: flex;
		align-items: center;

		.content {
			flex: 1;
			.cross {
				@extend .fa-solid;
				padding: 0 20px 10px 10px;
				float: right;
				&::before {
					content: fa-content($fa-var-xmark);
				}
			}
		}

		.icon {
			@extend .fa-solid;
			padding-right: 20px;
		}
	}
}

@include lg-xl {
	.flash-message-container {
		position: absolute;
		top: 0px;
		right: 0;
		gap: 10px;
		padding: 30px 50px;
		z-index: 1001;
		.flash-message-box {
			width: 350px;
			border-radius: 20px;
		}
	}
}
