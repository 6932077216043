@import "typography.scss";
@import "variables.scss";
@import "mixins.scss";

.manage-list {
	display: flex;
	flex-direction: column;
	gap: 5px;

	&.disabled {
		color: $text-dark-gray;
	}

	.manage-row {
		border-bottom: 1px solid $bg-line;
		@extend .text;
		&:last-child {
			border-bottom: 0;
		}
	}
}
