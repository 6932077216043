@import "flashMessage.scss";
@import "effects.scss";

.filter-container {
	.filters {
		display: flex;
		gap: 50px;
		.filter {
			//position: relative;
			.filter-name {
				display: flex;
				gap: 10px;
				align-items: center;
				@extend .text-button;
				cursor: pointer;
				.arrow {
					@include fa-icon-solid($fa-var-angle-down);
				}
			}

			&.active {
				.filter-name {
					color: $primary-color;
					//@include fa-icon-solid($fa-var-user);
					.arrow {
						@include fa-icon-solid($fa-var-angle-up);
					}
				}
				.filter-list-container {
					display: flex;
				}
			}

			.filter-list-container {
				position: absolute;
				display: none;
				margin-top: 10px;

				@include sm-md {
					left: 0px;
					width: 100%;
					.filter-list {
						margin: 0 25px;
						@include sm {
							margin: 0 10px;
						}
					}
				}
				.filter-list {
					background-color: $container-box-color;
					flex-direction: column;
					border-radius: 10px;
					width: 100%;

					@extend .shadow;
				}

				.filter-list-checkboxes {
					min-height: 10px;
					border-bottom: 1px solid $bg-line;
					padding: 15px 26px;
					max-height: 300px;
					overflow-y: scroll;
					.filter-list-checkbox {
						color: $text-dark-gray;
						label {
							&:hover,
							&:active {
								color: lighten($text-dark-gray, 10%) !important;
							}
						}
					}
				}
				.filter-list-footer {
					@extend .text-button;
					display: flex;
					justify-content: space-between;
					padding: 15px 26px;
				}
			}
		}
	}
}
