//colors
$bg-color: #f8f8f8;
$primary-color: #36a0a0;
$secondary-color: #2a7b7b;
$container-box-color: #ffffff;

$red: #b40000;
$red-hover: #9f0d0d;

$text-gray: #707070;
$text-gray-hover: #393939;
$text-light-gray: #afafaf;
$text-dark-gray: #474747;
$text-black-hover: #353535;

$error: #ff3b30;
$btn-delete: #ff7979;

$tag-fill: #faf8ca;
$tag-stroke: #edebc3;

$tag-secondary-fill: #fae1ca;
$tag-secondary-stroke: #edd7c3;

$tag-filter-fill: #ebebeb;
$tag-filter-stroke: #e0e0e0;

$btn-gray: #f1f1f1;

$input-line: #c2c2c2;
$bg-line: #dcdcdc;

$fixed-menu-padding: 20px;

//breakpoints
$screen-sm-min: 0px;
$screen-md-min: 480px;
$screen-lg-min: 740px;
$screen-xl-min: 992px;

$unloaded-header: #dcdcdc;
$unloaded-row: #ebebeb;

.error {
	color: $error !important;
}

.dark-gray {
	color: $text-dark-gray !important;
}

.gray {
	color: $text-gray !important;
}

.primary {
	color: $primary-color !important;
}
