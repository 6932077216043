@mixin sm-md {
	@media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-lg-min - 1}) {
		@content;
	}
}

@mixin lg-xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}

@mixin sm {
	@media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-min - 1}) {
		@content;
	}
}

// Medium devices
@mixin md {
	@media (min-width: #{$screen-md-min}) and (max-width: #{$screen-lg-min - 1}) {
		@content;
	}
}

// Large devices
@mixin lg {
	@media (min-width: #{$screen-lg-min}) and (max-width: #{$screen-xl-min - 1}) {
		@content;
	}
}

// Extra large devices
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}
