a {
	text-decoration: none;
	color: black;
}

.flex-space-between {
	display: flex;
	flex-direction: row !important;
	//align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 10px;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-wrap: wrap;
}

.justify-content-end {
	justify-content: end !important;
}

.w-100 {
	width: 100%;
}

.align-self-center {
	align-self: center;
}

.d-none {
	display: none;
}

.cursor-pointer {
	cursor: pointer;
}

.pt-2 {
	padding-top: 20px;
}

.no-wrap {
	white-space: nowrap;
}

.ml-4 {
	margin-left: 10px;
}

.text-center {
	text-align: center;
}
