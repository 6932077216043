//@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

$font-family: "Inter", sans-serif;

.h1 {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	line-height: 120%;
	text-align: center;
}

.h2 {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 115%;
	/* or 32px */
	letter-spacing: 0.02em;
}

.h3 {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 115%;
	/* identical to box height, or 28px */
	letter-spacing: 0.02em;
}

.text-simple {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 135%;
	/* or 24px */
}

.text-sm {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 135%;
}

.text-bold {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
}

.text-button {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
}

.text-button-sm {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
}

.text-menu {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 21px;
}

.input-inside {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
}

.input-label {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.04em;
}

.text {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 135%;
}

.input-label-sm {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.04em;
}

.text-board-date {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 135%;
}

.text-19 {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 140%;
}

.text-board-toggle {
	font-family: $font-family;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
}
