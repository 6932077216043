.shadow {
	box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.15);
}

.shadow-bg {
	box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.05);
}

.shadow-sm {
	box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.05);
}

.shadow-sm-menu {
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}
