@import "navigation.scss";

.board-toggle {
	border: 1px solid $secondary-color;
	border-radius: 29px;
	padding: 1px;
	display: flex;
	align-items: center;
	@extend .text-board-toggle;
	button {
		color: $secondary-color;
		@include sm {
			padding: 8px 14px;
			//border-radius: 28px !important;
		}
		&.active {
			color: white;
			background-color: $secondary-color;
			border-radius: 100px;
		}
	}
}

.board-notes-container {
	width: 100%;
	.board-notes {
		display: flex;
		gap: 5px;
		padding: 0 25px;
		justify-content: center;
		flex-wrap: wrap;
		@include sm-md {
			padding: 0;
		}

		&.own-notes {
			flex-direction: column;
			align-items: center;
			.board-note-container {
				max-width: 580px;
				.board-note-footer {
					display: flex;
					align-items: flex-end;
					flex-wrap: wrap;
					row-gap: 10px;
					column-gap: 5px;
					.image {
						flex: 3;
						min-width: 200px;
					}
					.board-note-footer-right {
						@extend .flex-end;
						gap: 5px;
						flex: 2;
						a,
						button {
							white-space: nowrap;
						}
					}
				}
			}
		}

		.board-note-container {
			max-width: 350px;
			width: 100%;
			justify-self: center;
			@include sm {
				max-width: 580px;
			}
			.board-note {
				display: grid;
				//flex-direction: column;
				padding: 20px 30px;
				@include sm {
					padding: 20px 20px;
				}
				gap: 5px;
				background-color: white;
				border-radius: 20px;
				grid-template-columns: auto 1fr;
				@extend .shadow-sm;
				grid-template-areas:
					"author badge"
					"title badge"
					"date date"
					"field field"
					"text text"
					"footer footer";
				.author {
					grid-area: author;
					@extend .text-button-sm;
				}
				.text {
					padding-top: 10px;
					grid-area: text;
					overflow: hidden;
				}
				.field {
					grid-area: field;
				}
				.date {
					grid-area: date;
				}
				.title {
					grid-area: title;
					@include sm {
						font-size: 22px;
					}
				}
				.badge {
					grid-area: badge;
					align-self: center;
					justify-self: end;
					@extend .text-button-sm;
					&.offering {
						&::before {
							content: "Nabízím";
						}
					}
					&.looking {
						&::before {
							content: "Sháním";
						}
					}
				}
				.board-note-footer {
					padding-top: 10px;
					grid-area: footer;
				}
				.image {
					width: 100%;
					overflow: hidden;
					img {
						//width: 100%;
						max-width: 100%;
						max-height: 250px;
						border-radius: 9px;
					}
				}
			}
		}
	}
}
