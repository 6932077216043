@import "filter.scss";
@import "general.scss";

//general

.alone-logo {
	position: absolute;
}

.navigation-logo {
	@extend .h3;
	&::before {
		content: "Lab";
	}
	&::after {
		content: "Net";
		color: $primary-color;
	}

	&.alone {
		padding: 20px 0 0 37px;
		@include md {
			padding: 20px 0 0 37px;
		}
		@include sm {
			padding: 20px 0 0 37px;
		}
	}
}

.navigation {
	background-color: $container-box-color;
	.navigation-header {
		.bars {
			display: none;
		}
	}

	.navigation-item {
		.navigation-item-text {
			@extend .text-menu;
		}
		color: black;
		&:hover {
			color: $text-black-hover;
		}

		&.logout {
			color: $red;
			&:hover {
				color: $red-hover;
			}
		}
		.icon {
			max-width: 20px;
			max-height: 20px;
		}
	}
}

.navigation {
	//md
	@include sm-md {
		position: fixed;
		box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.05);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 0px 0px 20px 20px;
		width: 100%;
		z-index: 1;
		> div {
			padding: 0 37px;
		}

		.navigation-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.navigation-logo {
				margin-top: $fixed-menu-padding;
				margin-bottom: $fixed-menu-padding;
			}
			.bars {
				display: initial;
			}
		}

		.navigation-main {
			display: flex;
			flex-direction: column;
			max-height: 0px;
			-webkit-transition: max-height 0.7s;
			-moz-transition: max-height 0.7s;
			-ms-transition: max-height 0.7s;
			-o-transition: max-height 0.7s;
			transition: max-height 0.7s;
			overflow: hidden;
			&.active {
				max-height: 550px;
			}
		}

		.navigation-menu {
			display: flex;
			flex-direction: column;
			.navigation-menu-item-container {
				display: flex;
				flex-direction: column;
				position: relative;

				.navigation-submenu-container {
					display: flex;
					flex-direction: column;
					-webkit-transition: max-height 0.7s;
					-moz-transition: max-height 0.7s;
					-ms-transition: max-height 0.7s;
					-o-transition: max-height 0.7s;
					transition: max-height 0.7s;
					overflow: hidden;
					max-height: 0;
					&.active,
					&.active-toggle {
						max-height: 200px;
					}
				}
			}
		}

		.navigation-item-container {
			display: flex;
			align-items: center;
			padding: 13px 0px;
		}

		.navigation-item {
			//margin: 0px 15px;
			&.submenu {
				color: $text-gray;
				padding: 13px 0;

				&:hover {
					color: $text-gray-hover;
				}
				&.active {
					color: $text-gray-hover;
				}
			}

			.navigation-item-text {
				flex-grow: 2;
				white-space: nowrap;
			}
			.icon {
				display: none;
			}

			&.logout {
				margin-bottom: $fixed-menu-padding;
			}
		}
	}

	//LG
	@include lg {
		box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.05);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-radius: 0px 0px 20px 20px;
		width: 100%;
		position: fixed;
		z-index: 1;
		.navigation-logo {
			margin: $fixed-menu-padding 10px $fixed-menu-padding 37px;
		}

		.navigation-main {
			display: flex;
			//margin-right: 27px;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			max-width: 600px;
		}

		.navigation-menu {
			display: flex;
			justify-content: flex-end;
			gap: 15px;
			width: 100%;
			.navigation-menu-item-container {
				display: flex;
				position: relative;

				.navigation-submenu-container {
					z-index: 1000;
					position: absolute;
					border-radius: 20px;
					box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.05);
					background-color: $container-box-color;
					top: 60px;
					left: -10px;
					display: flex;
					flex-direction: column;
					-webkit-transition: max-height 0.5s;
					-moz-transition: max-height 0.5s;
					-ms-transition: max-height 0.5s;
					-o-transition: max-height 0.5s;
					transition: max-height 0.5s;
					overflow: hidden;
					max-height: 0;
					&.active-toggle {
						max-height: 200px;
					}
					.navigation-item {
						display: initial;
						padding: 10px 20px;
					}
				}
			}
		}

		.navigation-item-container {
			display: flex;
			align-items: center;
			//padding: 13px 15px;
		}

		.navigation-item {
			&.submenu {
				color: $text-gray;
				padding: 13px 0;

				&:hover {
					color: $text-gray-hover;
				}
				&.active {
					color: $text-gray-hover;
				}
				&:first-child {
					margin-top: 15px;
				}
				&:last-child {
					margin-bottom: 15px;
				}
			}

			.navigation-item-text {
				flex-grow: 2;
				white-space: nowrap;
			}
			.icon {
				display: none;
			}
		}
	}
	//XL
	@include xl {
		box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.15);
		display: flex;
		flex-direction: column;
		gap: 42px;
		padding: 32px 7px 15px 7px;
		border-radius: 17px;
		width: fit-content;
		height: fit-content;
		margin: 20px 0 0 20px;
		position: sticky;
		top: 20px;

		.navigation-main {
			display: flex;
			flex-direction: column;
			gap: 42px;
		}

		.navigation-menu {
			display: flex;
			flex-direction: column;
			.navigation-menu-item-container {
				display: flex;
				flex-direction: column;
				gap: 5px;

				.navigation-submenu-container {
					position: initial;
					display: flex;
					flex-direction: column;
					-webkit-transition: max-height 0.5s;
					-moz-transition: max-height 0.5s;
					-ms-transition: max-height 0.5s;
					-o-transition: max-height 0.5s;
					transition: max-height 0.5s;
					overflow: hidden;
					max-height: 0;
					&.active,
					&.active-toggle {
						max-height: 200px;
					}
				}
			}
		}

		.navigation-item-container {
			display: flex;
			align-items: center;
			padding: 13px 0;
			&.active {
				&::before {
					content: "";
					position: absolute;
					width: 7px;
					height: 48px;
					left: 0px;
					background: $primary-color;
					border-radius: 0px 5px 5px 0px;
				}
			}
		}

		.navigation-item {
			display: grid;
			grid-template-columns: 40px auto;
			margin: 0px 35px 0px 30px;
			&.submenu {
				color: $text-gray;
				padding: 13px 0;
				&:last-child {
					margin-bottom: 10px;
				}

				&:hover {
					color: $text-gray-hover;
				}
				&.active {
					color: $text-gray-hover;
				}
			}

			.navigation-item-text {
				flex-grow: 2;
			}
		}
	}
}
